import React, { Component } from "react";

class SocialContact extends Component {
    render() {
        return (
            <ul className="social-other-address d-flex align-items-center">
                <li>
                    <div>
                        <a
                            href={this.props.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={require("../../../../static/assets/svg/index/facebook.svg")}
                                alt="Facebook"
                            />
                        </a>
                    </div>
                </li>
                <li>
                    <div>
                        <a
                            href={this.props.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={require("../../../../static/assets/svg/index/twitter.svg")}
                                alt="Twitter"
                            />
                        </a>
                    </div>
                </li>
                <li>
                    <div>
                        <a
                            href={this.props.linkedIn}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={require("../../../../static/assets/svg/index/linkedin.svg")}
                                alt="Linkedin"
                            />
                        </a>
                    </div>
                </li>
                {/* <li>
                    <div>
                        <img src={require('../../../assets/svg/index/worldwide.svg')} alt='' />
                    </div>
                </li> */}
                <li>
                    <div>
                        <a
                            href={this.props.location}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={require("../../../../static/assets/svg/index/location-blue.svg")}
                                alt="Location Blue"
                            />
                        </a>
                    </div>
                </li>
            </ul>
        );
    }
}

export default SocialContact;
