import React, { Component } from "react";
import FrontFooter from "./common/Front-Footer";
import SocialContact from "./common/Social-contact";
import OpenNotification from "../../components/common/CommonButton/OpenNotification";
import UtilService from "../../services/util";
import { notification } from "antd";
import _ from "lodash";
import FrontWrapper from "./common/FrontWrapper";
import IntlMessages from "../../util/IntlMessages";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        email:  "",
        mobile: ""
      },
      errors: {}
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleReset = () => {
    let fields = {
      email: localStorage.getItem("hkw-user")
        ? JSON.parse(localStorage.getItem("hkw-user")).email
        : "",
      mobile: localStorage.getItem("hkw-user")
        ? JSON.parse(localStorage.getItem("hkw-user")).mobile
        : ""
    };
    this.setState({ fields });
    document.getElementById("Create-Contact-form").reset();
  };

  handleSubmit = e => {
    e.preventDefault();
    let self = this;
    let reqObj = _.clone(this.state.fields);
    reqObj.isFrom = 1;
    let obj = {
      method: "post",
      url: "front/contactus/create",
      request: reqObj
    };
    if (self.handleValidation()) {
      UtilService.callApi(obj, function(err, data) {
        if (err) {
          return OpenNotification({
            type: "error",
            title: err.data.message
          });
        }
        else if (data && data.code === "OK") {
          OpenNotification({
            type: "success",
            title: data.message
          });
          self.handleReset();
        }
      });
    }
  };

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    let blankField = [];
    let invalidFiels = [];
    //Name
    if (!fields["name"]) {
      blankField.push("Name");
      formIsValid = false;
      errors["name"] = "Please enter full Name";
    }
    if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[a-zA-Z ]+$/)) {
        invalidFiels.push("Name");
        formIsValid = false;
        errors["name"] = "Please enter only letters.";
      }
    }
    //Email
    if (!fields["email"]) {
      blankField.push("Email");
      formIsValid = false;
      errors["email"] = "Please enter email address";
    }
    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        invalidFiels.push("Email");
        formIsValid = false;
        errors["email"] = "Email must be of type xyz@gmail.com";
      }
    }
    if (!fields["mobile"]) {
      blankField.push("Mobile");
      formIsValid = false;
      errors["mobile"] = "Please enter mobile number";
    }

    if (typeof fields["mobile"] !== "undefined") {
      if (
        !fields["mobile"].match(/(^[0-9]*)$/) ||
        fields["mobile"].length > 10 ||
        fields["mobile"].length < 10
      ) {
        invalidFiels.push("Mobile");
        formIsValid = false;
        errors["mobile"] = "Mobile number invalid";
      }
    }

    if (!fields["messege"]) {
      blankField.push("Message");
      formIsValid = false;
      errors["messege"] = "Please enter message";
    }

    if (blankField.length > 0) {
      let validateFields = blankField.map((f, index) => {
        return (
          <span>{`${f}${index !== blankField.length - 1 ? ", " : ""}`}</span>
        );
      });
      notification.error({
        message: "Required Fields",
        description: validateFields
      });
    }
    if (invalidFiels.length > 0) {
      let validateFields = invalidFiels.map((f, index) => {
        return (
          <span>{`${f} ${index !== invalidFiels.length - 1 ? "," : ""}`}</span>
        );
      });
      notification.error({
        message: "Invalid Fields",
        description: validateFields
      });
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  imageClick = () => {
    return window.open("/dashboard", "_blank");
  };

  render() {
    const fieldName = _.clone(this.state.fields);
    return (
      <FrontWrapper location={this.props.location}>
        <div className="scroll-content-wrapper" data-scrollbar>
          <div className="page-main page-current">
            <div
              className="page-toload legacy-page"
              data-bodyclassname="legacy"
            >
              <main className="page-content" role="main">
                <section className="section-container our-story our-service-bn contact-banner">
                  <div style={{ margin: "auto" }} className="mb-100">
                    <h2 className="show-text head padd-max js-type-animation-alt">
                      <IntlMessages id="app.ContactUs" />
                    </h2>
                  </div>

                  <div
                    className="container d-flex flex-wrap-xs"
                    style={{ background: "transparent" }}
                  >
                    <div
                      className="contact-form-bn"
                      style={{ background: "transparent" }}
                    >
                      <h5>
                        {" "}
                        <IntlMessages id="app.GetinTouch" />
                      </h5>
                      <form
                        id="Create-Contact-form"
                        onSubmit={this.handleSubmit}
                      >
                        <div className="two-col-sf d-flex align-items-center j-space-between flex-wrap-sm flex-wrap-xs">
                          <div
                            className="form-control full-width"
                            style={{
                              height: "80px"
                            }}
                          >
                            <label htmlFor="name">
                              <IntlMessages id="app.name" />{" "}
                              {!fieldName.name || fieldName.name === "" ? (
                                <span className="required">*</span>
                              ) : (
                                <span
                                  className="required"
                                  style={{ color: "green" }}
                                >
                                  *
                                </span>
                              )}
                            </label>
                            <input
                              className={
                                this.state.errors["name"] ? "input-error" : ""
                              }
                              onChange={this.handleChange.bind(this, "name")}
                              type="text"
                              id="name"
                              name="name"
                            />
                            <span style={{ color: "red", fontSize: "small" }}>
                              {this.state.errors["name"]}
                            </span>
                          </div>
                          <div
                            className="form-control full-width"
                            style={{
                              height: "80px"
                            }}
                          >
                            <label htmlFor="email">
                              <IntlMessages id="app.EmailAddress" />{" "}
                              {!fieldName.email || fieldName.email === "" ? (
                                <span className="required">*</span>
                              ) : (
                                <span
                                  className="required"
                                  style={{ color: "green" }}
                                >
                                  *
                                </span>
                              )}
                            </label>
                            <input
                              className={
                                this.state.errors["email"] ? "input-error" : ""
                              }
                              onChange={this.handleChange.bind(this, "email")}
                              value={this.state.fields.email || ""}
                              type="text"
                              id="email"
                              name="email"
                            />
                            <span style={{ color: "red", fontSize: "small" }}>
                              {this.state.errors["email"]}
                            </span>
                          </div>
                        </div>
                        <div className="two-col-sf d-flex align-items-center j-space-between flex-wrap-sm flex-wrap-xs">
                          <div
                            className="form-control full-width"
                            style={{
                              height: "80px"
                            }}
                          >
                            <label htmlFor="company">
                              <IntlMessages id="app.CompanyName" />
                            </label>
                            <input
                              onChange={this.handleChange.bind(this, "company")}
                              type="text"
                              id="company"
                              name="company"
                            />
                          </div>
                          <div
                            className="form-control full-width"
                            style={{
                              height: "80px",
                              marginTop: "-4px"
                            }}
                          >
                            <label htmlFor="mobile">
                              <IntlMessages id="app.my-account.mobile" />
                              {!fieldName.mobile || fieldName.mobile === "" ? (
                                <span className="required">*</span>
                              ) : (
                                <span
                                  className="required"
                                  style={{ color: "green" }}
                                >
                                  *
                                </span>
                              )}
                            </label>
                            <input
                              onChange={this.handleChange.bind(this, "mobile")}
                              className={
                                this.state.errors["mobile"] ? "input-error" : ""
                              }
                              value={this.state.fields.mobile || ""}
                              type="text"
                              id="mobile"
                              name="mobile"
                            />
                            <span style={{ color: "red", fontSize: "small" }}>
                              {this.state.errors["mobile"]}
                            </span>
                          </div>
                        </div>
                        <div className="form-control full-width">
                          <label htmlFor="messege">
                            <IntlMessages id="app.Message" />{" "}
                            {!fieldName.messege || fieldName.messege === "" ? (
                              <span className="required">*</span>
                            ) : (
                              <span
                                className="required"
                                style={{ color: "green" }}
                              >
                                *
                              </span>
                            )}
                          </label>
                          <textarea
                            onChange={this.handleChange.bind(this, "messege")}
                            className={
                              this.state.errors["messege"] ? "input-error" : ""
                            }
                            id="messege"
                            cols="30"
                            rows="5"
                            name="messege"
                            // placeholder="Your message here"
                          />
                          <span style={{ color: "red", fontSize: "small" }}>
                            {this.state.errors["messege"]}
                          </span>
                        </div>
                        <div className="text-right text-center-xs">
                          <button
                            type="submit"
                            className="button-tag btn btn-primary btn-small"
                          >
                            <IntlMessages id="app.SubmitMessage" />
                          </button>
                        </div>
                      </form>
                    </div>

                    <div className="contact-detail-bn">
                      <h5>
                        <IntlMessages id="app.Headoffice" />
                      </h5>
                      <div className="head-office-details d-flex">
                        <div className="head-icons head-icon-style">
                          <img
                            src={require("../../../static/assets/svg/index/location.svg")}
                            width="40px"
                            alt=""
                          />
                        </div>
                        <div className="office-detail addr">
                          <IntlMessages id="app.HariKrishnaExportsPvt.Ltd." />
                          <br />
                          1701, The Capital, 'B' Wing, Bandra Kurla Complex,
                          Bandra(East) Mumbai- 400 051, Maharashtra, India.
                        </div>
                      </div>
                      <div className="head-office-details d-flex">
                        <div className="head-icons">
                          <img
                            src={require("../../../static/assets/svg/index/email.svg")}
                            alt=""
                          />
                        </div>
                        <div className="office-detail">
                          <span style={{ color: "white" }}>info@hk.co,</span>
                          <span style={{ color: "white" }}>sales@hk.co</span>
                        </div>
                      </div>
                      <div className="head-office-details d-flex">
                        <div className="head-icons">
                          <img
                            src={require("../../../static/assets/svg/index/phonecall.svg")}
                            alt=""
                          />
                        </div>
                        <div className="office-detail">
                          <span style={{ color: "white" }}>
                            +91 22 4300 4300
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="service-bg-light services-listing margin-bottom-0 margin-top-0">
                  <div className="services-listing-heading m-b-60 mb-xs-20 ">
                    <h3>
                      {" "}
                      <IntlMessages id="app.HKAffiliates" />
                    </h3>
                  </div>

                  <div className="container d-flex container-other-address container-md">
                    <div className="other-address">
                      <div className="other-address-header d-flex align-items-center">
                        <div className="other-head-logo">
                          <img
                            className="imgHover"
                            src={require("../../../static/assets/svg/index/logo-affiliates.svg")}
                            alt=""
                            width="80px"
                            onClick={() => this.imageClick()}
                          />
                        </div>
                        <div className="other-head-name">
                          <IntlMessages id="app.HariKrishnaExportsPvt.Ltd." />{" "}
                          [Headquarters]
                        </div>
                      </div>
                      <div className="other-address-content">
                        <div className="addreddDetails">
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <a
                                href="https://goo.gl/maps/jEUksm14QRP2"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={require("../../../static/assets/svg/index/location-grey.svg")}
                                  alt=""
                                  width="40px"
                                />
                              </a>
                            </div>
                            <div className="office-detail">
                              1701, The Capital, 'B' Wing, Bandra Kurla Complex,
                              Bandra (East) Mumbai - 400 051, Maharashtra,
                              India.
                            </div>
                          </div>
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <img
                                src={require("../../../static/assets/svg/index/email-grey.svg")}
                                alt=""
                              />
                            </div>
                            <div className="office-detail">
                              {/* <a 
                            href={'mailto:info@hk.co'}
                            target='_blank'
                            rel='noopener noreferrer'> */}
                              <span>info@hk.co</span>
                              {/* </a> */}
                            </div>
                          </div>
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <img
                                src={require("../../../static/assets/svg/index/phonecall-grey.svg")}
                                alt=""
                              />
                            </div>
                            <div className="office-detail">
                              <span>+91-22-4300 4300</span>
                            </div>
                          </div>
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <img
                                src={require("../../../static/assets/svg/index/stdcall-grey.svg")}
                                alt=""
                              />
                            </div>
                            <div className="office-detail">
                              <span>+91-22-4300 4201</span>
                            </div>
                          </div>
                        </div>
                          <SocialContact
                         whatsapp="https://wa.me/919143004300"
                         location="https://goo.gl/maps/SENTkXJfi7LeC1WVA"
                         facebook="https://www.facebook.com/HKDiamonds"
                         twitter="https://twitter.com/HKDiamonds"
                         linkedIn="https://www.linkedin.com/company/hkdiamonds/"
                        />
                      </div>
                    </div>
                    <div className="other-address">
                      <div className="other-address-header d-flex align-items-center">
                        <div className="other-head-logo">
                          <img
                            className="imgHover"
                            src={require("../../../static/assets/svg/index/logo-affiliates.svg")}
                            alt=""
                            width="87px"
                            onClick={() => this.imageClick()}
                          />
                        </div>
                        <div className="other-head-name">
                          Hari Krishna Exports Pvt. Ltd [Manufacturing Unit]
                        </div>
                      </div>
                      <div className="other-address-content">
                        <div className="addreddDetails">
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <a
                                href="https://goo.gl/maps/p5DfwRUXA8bZc3VM9"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={require("../../../static/assets/svg/index/location-grey.svg")}
                                  alt=""
                                  width="40px"
                                />
                              </a>
                            </div>
                            <div className="office-detail">
                              9, Hari Krishna Campus, Near Vraj Chowk, Behind
                              Sarthana Jakatnaka, Varachha Road, Surat - 394
                              211, Gujarat, India.
                            </div>
                          </div>
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <img
                                src={require("../../../static/assets/svg/index/email-grey.svg")}
                                alt=""
                              />
                            </div>
                            <div className="office-detail">
                              <span>surat@hk.co</span>
                            </div>
                          </div>
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <img
                                src={require("../../../static/assets/svg/index/phonecall-grey.svg")}
                                alt=""
                              />
                            </div>
                            <div className="office-detail">
                              <span>+91-261-7120000</span>
                            </div>
                          </div>
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <img
                                src={require("../../../static/assets/svg/index/stdcall-grey.svg")}
                                alt=""
                              />
                            </div>
                            <div className="office-detail">
                              <span>+91-22-4300 4201</span>
                            </div>
                          </div>
                        </div>
                        <SocialContact
                         location="https://goo.gl/maps/uxWeUneD33kcu3xd6"
                         facebook="https://www.facebook.com/HKDiamonds"
                         twitter="https://twitter.com/HKDiamonds"
                         linkedIn="https://www.linkedin.com/company/hkdiamonds/"
                        />
                      </div>
                    </div>
                    <div className="other-address">
                      <div className="other-address-header d-flex align-items-center">
                        <div className="other-head-logo">
                          <img
                            className="imgHover"
                            src={require("../../../static/assets/svg/index/logo-affiliates.svg")}
                            alt=""
                            width="75px"
                            onClick={() => this.imageClick()}
                          />
                        </div>
                        <div className="other-head-name">
                          HK HUB Diamond LLP [Manufacturing Unit]
                        </div>
                      </div>
                      <div className="other-address-content">
                        <div className="addreddDetails">
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <a
                                href="https://goo.gl/maps/HGouXbJSm45sbDYs9"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={require("../../../static/assets/svg/index/location-grey.svg")}
                                  alt=""
                                  width="50px"
                                />
                              </a>
                            </div>
                            <div className="office-detail">
                              Plot No. D-02, Gujarat Hira Bourse, Gem &
                              Jewellery Park, Near GIDC Water Tank, ONGC Road,
                              Ichchhapore, Surat - 394 510, Gujarat, India
                            </div>
                          </div>
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <img
                                src={require("../../../static/assets/svg/index/email-grey.svg")}
                                alt=""
                              />
                            </div>
                            <div className="office-detail">
                              <span>surat@hk.co</span>
                            </div>
                          </div>
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <img
                                src={require("../../../static/assets/svg/index/phonecall-grey.svg")}
                                alt=""
                              />
                            </div>
                            <div className="office-detail">
                              <span>+91-261-7110000</span>
                            </div>
                          </div>
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <img
                                src={require("../../../static/assets/svg/index/stdcall-grey.svg")}
                                alt=""
                              />
                            </div>
                            <div className="office-detail">
                              <span>+91-261-7110001</span>
                            </div>
                          </div>
                        </div>
                        <SocialContact
                         location="https://goo.gl/maps/3n1M3N4Y1XZ6gyvTA"
                         facebook="https://www.facebook.com/HKDiamonds"
                         twitter="https://twitter.com/HKDiamonds"
                         linkedIn="https://www.linkedin.com/company/hkdiamonds/"
                        />
                      </div>
                    </div>
                    <div className="other-address">
                      <div className="other-address-header d-flex align-items-center">
                        <div className="other-head-logo">
                          <img
                            className="imgHover"
                            src={require("../../../static/assets/svg/index/logo-affiliates.svg")}
                            alt=""
                            width="60px"
                            onClick={() => this.imageClick()}
                          />
                        </div>
                        <div className="other-head-name">
                          H.K.Designs [India]
                        </div>
                      </div>
                      <div className="other-address-content">
                        <div className="addreddDetails">
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <a
                                href="https://goo.gl/maps/8weEPHBQhH4i7bET7"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={require("../../../static/assets/svg/index/location-grey.svg")}
                                  alt=""
                                  width="35px"
                                />
                              </a>
                            </div>
                            <div className="office-detail">
                              Unit No. 113, SDF IV, Seepz-Sez, Andheri (East),
                              Mumbai-400 096, Maharashtra, India.
                            </div>
                          </div>
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <img
                                src={require("../../../static/assets/svg/index/email-grey.svg")}
                                alt=""
                              />
                            </div>
                            <div className="office-detail">
                              <span>jewelry@hk.co</span>
                            </div>
                          </div>
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <img
                                src={require("../../../static/assets/svg/index/phonecall-grey.svg")}
                                alt=""
                              />
                            </div>
                            <div className="office-detail">
                              <span>+91-22-6716 1616</span>
                            </div>
                          </div>
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <img
                                src={require("../../../static/assets/svg/index/stdcall-grey.svg")}
                                alt=""
                              />
                            </div>
                            <div className="office-detail">
                              <span>+91 22 6716 1626</span>
                            </div>
                          </div>
                        </div>
                        <SocialContact
                         location="https://goo.gl/maps/EkVCa6oZrQ8JYPXd8"
                         facebook="https://www.facebook.com/HKDiamonds"
                         twitter="https://twitter.com/HKDiamonds"
                         linkedIn="https://www.linkedin.com/company/hkdiamonds/"
                        />
                      </div>
                    </div>
                    <div className="other-address">
                      <div className="other-address-header d-flex align-items-center">
                        <div className="other-head-logo">
                          <img
                            className="imgHover"
                            src={require("../../../static/assets/svg/index/logo-affiliates.svg")}
                            alt=""
                            width="60px"
                            onClick={() => this.imageClick()}
                          />
                        </div>
                        <div className="other-head-name">
                          Kisna Diamond Jewellery
                        </div>
                      </div>
                      <div className="other-address-content">
                        <div className="addreddDetails">
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <a
                                href="https://goo.gl/maps/ENrsswmhwkJtiujb7"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={require("../../../static/assets/svg/index/location-grey.svg")}
                                  alt=""
                                  width="50px"
                                />
                              </a>
                            </div>
                            <div className="office-detail">
                              H.K Jewels Pvt. Ltd. 1701, The Capital, 'B' Wing,
                              Bandra Kurla Complex, Bandra (East) Mumbai - 400
                              051, Maharashtra, India.
                            </div>
                          </div>
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <img
                                src={require("../../../static/assets/svg/index/email-grey.svg")}
                                alt=""
                              />
                            </div>
                            <div className="office-detail">
                              <span>info@kisna.com </span>
                            </div>
                          </div>
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <img
                                src={require("../../../static/assets/svg/index/phonecall-grey.svg")}
                                alt=""
                              />
                            </div>
                            <div className="office-detail">
                              <span>+91-22 6716 0000</span>
                            </div>
                          </div>
                          <div className="head-office-details d-flex">
                            <div className="head-icons">
                              <img
                                src={require("../../../static/assets/svg/index/stdcall-grey.svg")}
                                alt=""
                              />
                            </div>
                            <div className="office-detail">
                              <span>1800 22 1234</span>
                            </div>
                          </div>
                        </div>
                        <SocialContact
                         location="https://g.page/kisna-diamond-jewellery-mumbai?share"
                         facebook="https://www.facebook.com/KisnaDiamondJewellery"
                         twitter="https://twitter.com/kisnaindia"
                         linkedIn="https://www.linkedin.com/company/hkdiamonds/"
                        />
                      </div>
                    </div>
                  </div>
                </section>

                <FrontFooter />
              </main>
              <div className="preload" aria-hidden="true" />
            </div>
          </div>
          <div className="page-main page-next" aria-hidden="true" />
          <div className="page-main page-prev" aria-hidden="true" />
          <footer className="footer" role="contentinfo" />
        </div>
      </FrontWrapper>
    );
  }
}

export default Contact;
